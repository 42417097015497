$(function() {
    $(window).on("scroll", function() {
        if($(window).scrollTop() > 100) {
            $(".header-area").addClass("active");
        } else {
            //remove the background property so it comes transparent again (defined in your css)
           $(".header-area").removeClass("active");
        }
    });
});

$(function() {
	$("#quantity").blur(function() {
		if ($("#quantity").val() > 0 && $("#quantity").val() <= 10) {
			$("#total-amount").val('R$ ' + parseFloat($("#quantity").val()*$("#product_price").val()).toFixed(2));
		} else {
			$("#total-amount").val('R$ ' + parseFloat($("#product_price").val()).toFixed(2));
			alert("Quantidade inválida. A quantidade permitida é de 1 a 10 passaportes por pedido.");
			$("#quantity").val(1);
			$("#quantity").focus();
		}
	});
});

/*$(document).ready(function(){
	$("#modalWarning").fadeIn();
});*/

$(document).ready(function(){
	$("#modalEstiagem").fadeIn();
});

$(function() {
	$("#btn-passports-next").click(function() {
		$("#passport-amount").hide();
		$("#passport-names").fadeIn();

		for (var i = 10; i > $("#quantity").val(); i--) {
			var s = "#passport-fields-" + i;
			$(s).hide();
		};
		for (var i = 1; i <= $("#quantity").val(); i++) {
			var s = "#passport-fields-" + i;
			$(s).show();	
		};	
	});

	$("#btn-passport-amount").click(function() {
		$("#passport-names").hide();
		$("#passport-amount").fadeIn();	
	});

	$("#btn-payment").click(function() {
		$("#passport-names").hide();
		$("#payment-datas").fadeIn();
	});

	$("#btn-passport-back").click(function() {
		$("#payment-datas").hide();
		$("#passport-names").fadeIn();	
	});

	$(".passport-name-1").focus(function() {
		$(".passport-name-1").select();
	});
	$(".passport-name-2").focus(function() {
		$(".passport-name-2").select();
	});
	$(".passport-name-3").focus(function() {
		$(".passport-name-3").select();
	});
	$(".passport-name-4").focus(function() {
		$(".passport-name-4").select();
	});
	$(".passport-name-5").focus(function() {
		$(".passport-name-5").select();
	});
	$(".passport-name-6").focus(function() {
		$(".passport-name-6").select();
	});
	$(".passport-name-7").focus(function() {
		$(".passport-name-7").select();
	});
	$(".passport-name-8").focus(function() {
		$(".passport-name-8").select();
	});
	$(".passport-name-9").focus(function() {
		$(".passport-name-9").select();
	});
	$(".passport-name-10").focus(function() {
		$(".passport-name-10").select();
	});

	$(".passport-cpf-1").focus(function() {
		$(".passport-cpf-1").select();
	});
	$(".passport-cpf-2").focus(function() {
		$(".passport-cpf-2").select();
	});
	$(".passport-cpf-3").focus(function() {
		$(".passport-cpf-3").select();
	});
	$(".passport-cpf-4").focus(function() {
		$(".passport-cpf-4").select();
	});
	$(".passport-cpf-5").focus(function() {
		$(".passport-cpf-5").select();
	});
	$(".passport-cpf-6").focus(function() {
		$(".passport-cpf-6").select();
	});
	$(".passport-cpf-7").focus(function() {
		$(".passport-cpf-7").select();
	});
	$(".passport-cpf-8").focus(function() {
		$(".passport-cpf-8").select();
	});
	$(".passport-cpf-9").focus(function() {
		$(".passport-cpf-9").select();
	});
	$(".passport-cpf-10").focus(function() {
		$(".passport-cpf-10").select();
	});
});